import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Link from "gatsby-link";
import Podcast from "../images/podcast-home.png";
import YouTube from "../images/youtube-home.png";
import Tutoring from "../images/tutoring-home.png";

const typeRow = {
  height: "50px",
};

const smallIcon = {
  width: "50px",
  height: "50px",
};

const ContentBanner = (props) => {
  const site = props.data.site.siteMetadata;
  const isTransparent = props.props.isTransparent;
  return (
    <div
      className={`jumbotron jumbotron-fluid social-platform pt-1 pt-md-2 pb-2 pb-md-2 mb-0 ${
        isTransparent ? "transparent" : ""
      }`}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div
            className="col-4 d-flex justify-content-center align-items-center mt-1 mb-2 mt-md-1 mb-md-1"
            style={typeRow}
          >
            <Link
              className="card-link d-flex flex-column flex-md-row align-items-center"
              to="/podcast"
            >
              <img
                style={smallIcon}
                src={Podcast}
                alt="Vietnamese Podcast"
              ></img>

              <h3 className="social-platform-headings ml-1 pr-1 mb-0">
                Podcast
              </h3>
            </Link>
          </div>
          <div
            className="col-4 d-flex justify-content-center align-items-center mt-1 mb-2 mt-md-1 mb-md-1"
            style={typeRow}
          >
            <Link
              className="card-link d-flex flex-column flex-md-row align-items-center"
              href="/youtube"
            >
              <img
                style={smallIcon}
                src={YouTube}
                alt="Vietnamese YouTube Channel"
              ></img>
              <h3 className="social-platform-headings pl-1 pr-1 mb-0">
                YouTube
              </h3>
            </Link>
          </div>
          {/* <div
            className="col-4 d-flex justify-content-center align-items-center mt-1 mb-2 mt-md-1 mb-md-1"
            style={typeRow}
          >
            <Link
              className="card-link d-flex flex-column flex-md-row align-items-center "
              to="/vietnamese-class"
            >
              <img
                style={smallIcon}
                src={Tutoring}
                alt="Vietnamese Tutoring Class"
              ></img>
              <h3 className="social-platform-headings pl-1 pr-1 mb-0">
                Tutoring
              </h3>
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
            contact {
              email
              phone
              podcast
              facebook
              youtube
            }
          }
        }
      }
    `}
    render={(data) => <ContentBanner props={props} data={data} />}
  />
);
